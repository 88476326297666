.transparent {
  color: transparent;
}

.ace_text-input {
  position: absolute !important;
}

.enki-toast {
  padding: 2em;
  border-radius: 10px;
}

.primary {
  color: #70ffbc;
}

.enki-toast.Toastify__toast--success {
  border-color: #3beba8;
  color: #70ffbc;
  background-color: #19704f;
  border-radius: 8px;
  text-align: center;
}

.Toastify__toast-body {
  font-size: 1.1em;
  font-family: 'DM Sans', serif;
}

.enki-toast .Toastify__close-button {
  display: none;
}

.initial {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
}

.react-tooltip-lite {
  background: #585e7e;
  padding: 0 !important;
  box-shadow: 0 0 50px #202029;
  border-radius: 8px;
}

.react-tooltip-lite-arrow {
  border-color: #585e7e;
}

*::selection {
  background-color: rgb(112, 255, 188);
  color: #000;
}

.wrapper.fullscreen {
  padding: 0 !important;
  min-height: calc(100vh - 3.7em);
}

.wrapper > .inner {
  padding: 1em !important;
}

@media screen and (min-width: 768px) {
  .wrapper > .inner {
    padding: 2em !important;
  }
}

#navbar-main + #wrapper {
  margin-top: 0 !important;
}

.no-scroll {
  overflow: hidden;
}

blockquote > *:last-child {
  margin-bottom: 0;
}
